
import request from '../util/request'

// 获取政采招标
export const getpoliticsInTheTenderList = (params) => {
  return request({
    url: '/api/demo/Tender/list',
    method: 'post',
    data: {
      tender_industry_id: params.tender_industry_id || '',
      pro_id: params.pro_id || '',
      tender_status_end: params.tender_status_end || '',
      tender_status: params.tender_status || '',
      release_time_seven: params.release_time_seven || '',
      release_time_thirty: params.release_time_thirty || '',
      help_money_start: params.help_money_start || '',
      help_money_end: params.help_money_end || '',
      limit: params.limit || '',
      page: params.page || '',
      title: params.title || ''
    }

  })
}

// 获取政商招标信息2
export const getpoliticsInTheTenderList3 = (params) => {
  return request({
    url: '/api/demo/Tender/countt',
    method: 'post',
    data: {
      tender_industry_id: params.tender_industry_id || '',
      pro_id: params.pro_id || '',
      tender_status_end: params.tender_status_end || '',
      tender_status: params.tender_status || '',
      release_time_seven: params.release_time_seven || '',
      release_time_thirty: params.release_time_thirty || '',
      help_money_start: params.help_money_start || '',
      help_money_end: params.help_money_end || '',
      limit: params.limit || '',
      page: params.page || '',
      title: params.title || ''
    }

  })
}
// 获取研究领域
export const getResearchField = () => {
  return request({
    url: '/api/demo/ProjectGov/industry',
    method: 'get'

  })
}
// 获取招标地区
export const getSupportArea = () => {
  return request({
    url: '/api/demo/ProjectGov/pro',
    method: 'get'

  })
}
// 获取关键词列表
export const getKeywords = (params) => {
  return request({
    url: '/api/demo/subscribe/key_words',
    method: 'post',
    data: {
      keywords: params
    }

  })
}
// 获取非会员页面地址
export const getPage = () => {
  return request({
    url: '/api/demo/search/page',
    method: 'get'

  })
}