<template>
  <div class="background">
    <div class="typeArea title">政采招标</div>
    <div class="searcharea" v-if="isshowsearch">
      <div class="searcharea-title">
        <div class="title-key">
          <div class="leftborder">
            <div class="row"></div>
            <div class="column"></div>
          </div>
          <div>{{ serchkeywords }}</div>
          <div class="rightborder">
            <div class="column"></div>
            <div class="row"></div>
          </div>
        </div>
        <div class="title-scribe">相关领域</div>
      </div>
      <div class="searcharea-content">
        <div
          class="content-item"
          v-for="item in keywordsList"
          :key="item.TENDER_ID"
          :title="item.KEYWORD_SHOW"
          @click="keyWordsClick(item)"
        >
          {{ item.KEYWORD_SHOW }}
        </div>
      </div>
    </div>
    <!-- {{researchFieldAlreadyCheckedList}}{{researchFieldAlreadyChecked}} -->
    <div class="screening typeArea">
      <div class="search">
        <div class="search-image" @click="searchimgclick">
          <img src="../../../assets/image/申报指南/搜索.png" alt="" />
        </div>
        <div class="seach-input">
          <input
            v-model="serchkeywords"
            @keyup.enter="search($event)"
            @input="searchchange"
            @blur="searchblur"
            type="search"
            placeholder="请输入关键词，添加检索条件筛选相关信息，如“知识图谱”"
          />
        </div>
      </div>
      <div class="classification typeArea">
        <div class="classification-left">
          <div class="item research-field">
            <div class="item-title">研究领域</div>
            <div
              class="item-content"
              :class="{ 'div-overflow-hidden-1': screeningIsShow }"
            >
              <span
                :class="{ active: researchFieldChecked === -1 }"
                @click="researchFieldClick(-1, $event)"
                style="cursor: pointer"
              >
                不限
              </span>

              <span
                :class="{ active: item.ischecked }"
                @click="researchFieldClick(item, $event)"
                style="cursor: pointer"
                v-for="item in researchField"
                :key="item.INDUSTRY_ID"
                >{{ item.INDUSTRY_NAME }}</span
              >
            </div>
          </div>
          <div class="item">
            <div class="item-title">招标地区</div>
            <div
              class="item-content support-area"
              :class="{ 'div-overflow-hidden-1': screeningIsShow }"
            >
              <span
                :class="{ active: supportAreaChecked === -1 }"
                @click="supportAreaClick(-1, $event)"
                style="cursor: pointer"
              >
                全国
              </span>
              <span
                :class="{ active: supportAreaChecked === item.AREA_ID }"
                @click="supportAreaClick(item, $event)"
                v-for="item in supportAreagroup"
                :key="item.AREA_ID"
                style="cursor: pointer"
                >{{ item.AREA_NAME || item }}</span
              >
            </div>
          </div>
          <div class="item more-title">
            <div class="time">
              <div class="item-title">发布时间</div>
              <div
                class="item-content"
                :class="{ 'div-overflow-hidden-1': screeningIsShow }"
              >
                <span
                  :class="{ active: releaseTimeChecked === -1 }"
                  @click="releaseTimeClick(-1, $event)"
                  style="cursor: pointer"
                >
                  不限
                </span>
                <span
                  style="cursor: pointer"
                  :class="{ active: releaseTimeChecked === 1 }"
                  @click="releaseTimeClick(1, $event)"
                  >最近七天</span
                >
                <span
                  style="cursor: pointer"
                  :class="{ active: releaseTimeChecked === 2 }"
                  @click="releaseTimeClick(2, $event)"
                  >最近30天</span
                >
              </div>
            </div>
            <div class="project-status">
              <div class="item-title">项目状态</div>
              <div
                class="item-content"
                :class="{ 'div-overflow-hidden-1': screeningIsShow }"
              >
                <span
                  :class="{ active: projectStatusChecked === -1 }"
                  @click="projectStatusClick(-1, $event)"
                >
                  不限
                </span>
                <span
                  :class="{ active: projectStatusChecked === 1 }"
                  @click="projectStatusClick(1, $event)"
                  >招标中</span
                >
                <span
                  :class="{ active: projectStatusChecked === 2 }"
                  @click="projectStatusClick(2, $event)"
                  >已结束</span
                >
              </div>
            </div>
            <div class="funding-jine">
              <div class="item-title">项目金额</div>
              <div
                class="item-content"
                :class="{ 'div-overflow-hidden-1': screeningIsShow }"
              >
                <span
                  :class="{ active: grantsChecked === -1 }"
                  @click="moneynolimit"
                >
                  不限
                </span>
                <div
                  class="price"
                  :class="{ 'div-overflow-hidden-1': screeningIsShow }"
                >
                  <input
                    @focus="confirmbuttonIshide = false"
                    type="text"
                    v-model="mingrantsAlready"
                  />
                  <span class="horizontal-line"></span>
                  <input
                    @focus="confirmbuttonIshide = false"
                    type="text"
                    v-model="maxgrantsAlready"
                  />
                  <span>万元</span>
                  <span
                    class="confirm-button"
                    :class="{ hide: confirmbuttonIshide }"
                    @click="confirmButtonClick($event)"
                  >
                    确定
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="classification-right">
          <div class="pack-up" @click="toogleScreenstate">
            <span v-if="screeningIsShow">展开</span>
            <span v-else>收起</span>
            <div
              class="shouqiarrow"
              :class="{ tozhankaiarrow: screeningIsShow }"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="selectedConditions typeArea">
      <div class="selectedConditions-title">已选条件</div>
      <div class="content">
        <div
          class="item"
          v-for="(item, index) in selectedConditionsLIstanbul"
          :key="index"
        >
          <span>{{ item.title }}:{{ item.showcheck }}</span>
          <span @click="delSelectedConditions(item)"
            ><img src="../../../assets/image/关闭.png" alt=""
          /></span>
        </div>
        <div class="clear" @click="clearallchecked">
          <span>
            <img src="../../../assets/image/清除.png" alt="" />
          </span>
          <span>清空选项</span>
        </div>
      </div>
    </div>
    <div class="list typeArea">
      <div class="total">
        <div class="vertical-line"></div>
        <div>
          共<span>{{ datatotal }}</span
          >条结果
        </div>
      </div>
      <div class="list-table">
        <table>
          <thead>
            <tr>
              <td>状态</td>
              <td>课题名称</td>
              <td>发布机构</td>
              <td>主要方向</td>
              <td>招标所在地</td>
              <td>项目金额<br />(万元)</td>
              <td>发布时间</td>
              <td>截止时间</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in politicsInTheTenderList" :key="item.id">
              <td v-if="item.TENDER_END_TIME > item.TENDER_START_TIME">
                招标中
              </td>
              <td class="asfo" v-else></td>
              <td :title="item.TENDER_NAME" @click="toNewTenderDetail(item)">
                {{ item.TENDER_NAME }}
              </td>
              <td :title="item.TENDER_ORGANIZATION">
                <span
                  v-if="
                    item.TENDER_ORGANIZATION == null ||
                    item.TENDER_ORGANIZATION == false ||
                    item.TENDER_ORGANIZATION == ''
                  "
                  >——</span
                ><span v-else>{{ item.TENDER_ORGANIZATION }}</span>
              </td>
              <td :title="item.TENDER_KEYWORDS">
                <span
                  v-if="
                    item.TENDER_KEYWORDS == null ||
                    item.TENDER_KEYWORDS == '' ||
                    item.TENDER_KEYWORDS == false
                  "
                  >——</span
                >
                <span v-else>{{ item.TENDER_KEYWORDS }}</span>
              </td>
              <td>
                <span
                  v-if="
                    item.TENDER_PROVINCE === null ||
                    item.TENDER_PROVINCE === '' ||
                    item.TENDER_PROVINCE === false
                  "
                  >——</span
                >
                {{ item.TENDER_PROVINCE }}
              </td>
              <td>
                <span
                  v-if="
                    item.TENDER_MONEY === null ||
                    item.TENDER_MONEY === '' ||
                    item.TENDER_MONEY === false
                  "
                  >——</span
                >
                <span v-else>{{ item.TENDER_MONEY }}</span>
              </td>
              <td>{{ item.dateformatestart }}</td>
              <td>{{ item.dateformateend }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="paging typeArea" v-if="pageTotal > 1">
      <ul class="pagination">
        <!-- <li>
          <span>共<span>{{pageTotal}}</span>页</span>
                </li> -->
        <!-- <li @click="homePage()">
          <a> 首页</a>
        </li> -->
        <li @click="prePage()" :class="page <= 1 ? 'disabled' : ''">
          <a>&lt; </a>
        </li>
        <li
          v-for="(item, index) in pages"
          :class="page == item ? 'active' : ''"
          :key="index"
          @click="curPage(item)"
        >
          <a href="javascript:void(0)">{{ item }}</a>
        </li>
        <li @click="nextPage()" :class="page >= pageTotal ? 'disabled' : ''">
          <a> &gt; </a>
        </li>
        <!-- <li @click="endPage()">
          <a>末页 </a>
        </li> -->
        <!-- <div
          class="opt-jump"
          style="display: inline-block;"
        >
          <span
            @click="pageJump"
            style="cursor: pointer;"
          >跳转</span>
          <input
            type="text"
            v-model="jumpInput"
          />
        </div> -->
      </ul>
    </div>

    <!-- vip弹窗 -->
    <div class="z_vip" v-if="viptc">
      <div class="dt">
        <a class="close" @click="z_close">+</a>
        <img src="../../../assets/image/tc1.png" />
        <div class="h1">查看更多项目信息需要VIP会员资格哦</div>
        <div class="h2">
          您可以邀请好友免费获得VIP会员<br />资格，或直接付费秒速升级
        </div>
        <div class="anniu">
          <a @click="vip"
            ><img src="../../../assets/image/tc2.png" />付费升级</a
          >
          <a @click="vip1"
            ><img src="../../../assets/image/tc3.png" />免费升级</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import {
  getpoliticsInTheTenderList,
  getpoliticsInTheTenderList3,
  getResearchField,
  getSupportArea,
  getKeywords,
  getPage
} from "../../../api/politicsInTheTender";
// eslint-disable-next-line no-unused-vars
import { formatDate, makePy, checkCh, mkRslt } from "../../../util/tools";
// import axios from 'axios'
import { Addhistoricalrecordzc } from "../../../api/myRecentInformation";
export default {
  data() {
    return {
      // 控制分页
      // 总页数
      pageTotal: 0,
      // 页码
      pageNo: 1,
      // 当前页
      page: 1,
      // 每一页的条数
      pageSize: 12,
      // 总条数
      rows: 1,
      // 跳转input输入框的值
      jumpInput: "",
      // 控制筛选条件是否隐藏
      screeningIsShow: false,

      // 研究领域列表
      researchField: [],
      // 招标地区列表
      supportArea: [],

      // 控制选中样式
      // 研究领域
      researchFieldChecked: -1,
      // 招标地区
      supportAreaChecked: -1,
      // 发布时间
      releaseTimeChecked: -1,
      // 项目状态
      projectStatusChecked: -1,
      // 项目金额
      grantsChecked: -1,
      // 价格确认按钮是否隐藏
      confirmbuttonIshide: true,

      // 用户已选项
      // 研究领域
      researchFieldAlreadyChecked: [],
      // 研究领域已选列表
      researchFieldAlreadyCheckedList: [],
      // 招标地区
      supportAreaAlreadyChecked: "",
      // 发布时间
      // 1代表最近7天，2代表最近30天
      releaseTimeAlreadyChecked: -1,
      // 项目状态
      // 1代表申报中，2代表已结束
      projectStatusAlreadyChecked: -1,
      // 项目金额
      grantsAlreadyChecked: -1,
      // 最低资助价格
      mingrantsAlready: "",
      // 最高资助价格
      maxgrantsAlready: "",
      // 输入关键词
      serchkeywords: "",
      // 已选条件列表
      selectedConditionsLIstanbul: [],
      // 政采招标列表
      politicsInTheTenderList: [],
      // 政采招标项目总数
      datatotal: "",
      // 是否显示搜索下拉
      isshowsearch: false,
      // 相关领域数据
      keywordsList: [],
      z_page: "2",
      viptc: false,
    };
  },
  created() {
    // 获取初始化数据
    this.getData();
  },
  mounted() {},
  watch: {
    mingrantsAlready(val, oldval) {
      this.$data.mingrantsAlready = this.$data.mingrantsAlready
        .replace(/[^\d.]/g, "") // 去除非数字和小数点 eg：'AB..000b.2b.2..24.c'--->'..000.2.2..24.'
        .replace(/^\.*/, "") // 去除开头的小数点 eg: '..000.2.2..24.'--->'000.2.2..24'
        .replace(/(?<=(\.).*)\1/g, "") // 只保留一个小数点 eg：'000.2.2..24'--->'000.2224'
        .replace(/(^0+)(\d)(\.?)/g, "$2$3"); // 去除前面多余的0 eg：'000.2224'--->'0.2224'
    },
    maxgrantsAlready(val, oldval) {
      this.$data.maxgrantsAlready = this.$data.maxgrantsAlready
        .replace(/[^\d.]/g, "") // 去除非数字和小数点 eg：'AB..000b.2b.2..24.c'--->'..000.2.2..24.'
        .replace(/^\.*/, "") // 去除开头的小数点 eg: '..000.2.2..24.'--->'000.2.2..24'
        .replace(/(?<=(\.).*)\1/g, "") // 只保留一个小数点 eg：'000.2.2..24'--->'000.2224'
        .replace(/(^0+)(\d)(\.?)/g, "$2$3"); // 去除前面多余的0 eg：'000.2224'--->'0.2224'
    },
  },
  computed: {
    pages() {
      const start = this.page;
      let end = this.pageTotal;
      if (end >= 5) {
        // end = 5;
      }
      if (end < 10) return end;
      if (start <= 5) {
        return [1, 2, 3, 4, 5, 6, "...", end];
      } else if (start > end - 5) {
        return [1, "...", end - 5, end - 4, end - 3, end - 2, end - 1, end];
      } else {
        return [
          1,
          "...",
          start - 3,
          start - 2,
          start - 1,
          start,
          start + 1,
          start + 2,
          "...",
          end,
        ];
      }
    },
    supportAreagroup() {
      const arr = this.$data.supportArea;
      const arr1 = this.arrSortByName(arr, "AREA_NAME");
      const arr2 = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];
      let arr3 = [];
      arr1.forEach((item) => {
        if (arr2.indexOf(makePy(item.AREA_NAME)[0].slice(0, 1)) !== -1) {
          if (arr3.indexOf(makePy(item.AREA_NAME)[0].slice(0, 1)) === -1) {
            arr3.push(makePy(item.AREA_NAME)[0].slice(0, 1));
          }
        }
      });
      arr3 = arr3.sort();
      const arr4 = [];
      arr3.forEach((ite) => {
        arr4.push(ite);
        arr1.forEach((item) => {
          if (ite === makePy(item.sortName)[0].slice(0, 1)) {
            if (item.sortName !== "全国") {
              arr4.push(item);
            } else {
            }
          }
        });
      });
      return arr4;
    },
  },
  methods: {
    // 数组按照字段首字母排序
    arrSortByName(arr, eachName) {
      if (eachName) {
        arr.forEach(function (item) {
          const temp = item[eachName];
          item.sortName = temp;
        });
        return arr.sort(function (param1, param2) {
          return param1.sortName.localeCompare(param2.sortName, "zh");
        });
      } else {
        return arr.sort(function (param1, param2) {
          return param1.localeCompare(param2, "zh");
        });
      }
    },
    // 格式化日期格式
    formateDateList(arr) {
      arr.forEach((element) => {
        if (element.TENDER_START_TIME) {
          // eslint-disable-next-line eqeqeq
          if (element.TENDER_START_TIME == false) {
            element.dateformatestart = "——";
          } else {
            element.dateformatestart = formatDate(
              parseInt(element.TENDER_RELEASE_TIME)
            )
              .trim()
              .replace(/-/g, "/");
          }

          // eslint-disable-next-line eqeqeq
          if (element.TENDER_END_TIME == false) {
            element.dateformateend = "——";
          } else {
            element.dateformateend = formatDate(
              parseInt(element.TENDER_END_TIME)
            )
              .trim()
              .replace(/-/g, "/");
          }
        }
      });
    },
    // 数据获取
    getData() {
      const that = this;
      // 获取非会员页面
      getPage().then(
        (res) => {
          console.log("非会员页面", res[0].page);
          this.z_page = res[0].page
        }
      );
      // 获取政采招标列表
      getpoliticsInTheTenderList({
        limit: that.$data.pageSize,
        page: that.$data.page,
      }).then(
        (res) => {
          console.log("res", res);
          that.formateDateList(res.data);
          that.$data.politicsInTheTenderList = res.data;
        },
        (err) => console.log(err)
      );

      getpoliticsInTheTenderList3({
        limit: that.$data.pageSize,
        page: that.$data.page,
      }).then(
        (res) => {
          that.$data.datatotal = res.count;
          that.$data.pageTotal =
            res.count % that.$data.pageSize === 0
              ? Math.floor(res.count / that.$data.pageSize)
              : Math.floor(res.count / that.$data.pageSize) + 1;
          if (that.$data.pageTotal >= 5) {
            // that.$data.pageTotal = 5;
          }
        },
        (err) => console.log(err)
      );

      // 获取研究领域列表
      getResearchField().then(
        (res) => {
          const arr = res.data;
          arr.forEach((ele) => {
            ele.ischecked = false;
          });
          console.log("研究领域", res);
          that.$data.researchField = arr;
        },
        (err) => {
          console.log(err);
        }
      );
      // 获取资助地区列表
      getSupportArea().then(
        (res) => {
          that.$data.supportArea = res.data;
        },
        (err) => {
          console.log(err);
        }
      );
    },
    // 展示详情页
    showRepotrDetails() {
      this.$router.push({
        path: "reportingGuidelinesDetails",
        query: "1234",
      });
      window._czc.push(["_trackEvent", "点击", "跳转到政采招标详情页"]);
    },
    // 分页
    // 当前页
    // curPage(i) {
    //   if (i === "...") return;
    //   this.page = i;
    //   this.serarchByUserSelect();
    // },
    curPage(i) {
      if (i === "...") return;
      if (this.$store.state.ispro == 'true') {
        this.page = i;
        this.serarchByUserSelect();
      } else {
        if (i <= this.z_page) {
          this.page = i;
          this.serarchByUserSelect();
        } else {
          this.viptc = true;
        }
      }
      console.log(this.$store.state.ispro);
    },
    // 下一页
    nextPage() {
      if (this.page < this.pageTotal) {
        if (this.page === 5) {
        } else if (this.$store.state.ispro == 'false') {
          if(this.page >= this.z_page){
            this.viptc = true;
          } else {
            this.page = Number(this.page) + 1;
            this.serarchByUserSelect();
          }
        } else {
          this.page = Number(this.page) + 1;
          this.serarchByUserSelect();
        }
        // this.search()
      }
    },
    // 上一页
    prePage() {
      if (this.page > 1) {
        this.page = Number(this.page) - 1;
        this.serarchByUserSelect();
        // this.search()
        // this.paginationByNo(--this.pageNo, this.pageSize)
      }
    },
    // 首页
    homePage() {
      this.pageNo = 1;
      this.page = 1;
      this.search();
    },
    // 末页
    endPage() {
      this.page = this.pageTotal;
      this.search();
    },
    // 分页跳转功能
    pageJump() {
      const val = this.$data.jumpInput;
      const total = this.$data.pageTotal;
      if (val !== "") {
        if (val > 0 && val <= total) {
          this.$data.pageNo = this.$data.jumpInput;
          this.paginationByNo(this.pageNo, this.pageSize);
        } else {
        }
      } else {
      }
    },

    // 页面绑定事件方法
    // 筛选区域收起按钮事件绑定
    toogleScreenstate() {
      this.$data.screeningIsShow = !this.$data.screeningIsShow;
    },
    // 研究领域点击事件
    researchFieldClick(item, event) {
      const that = this;
      if (item === -1) {
        item = {
          INDUSTRY_NAME: "不限",
          INDUSTRY_ID: -1,
        };
        that.$data.researchFieldAlreadyChecked = [];
        that.$data.researchFieldChecked = -1;
        const arr = that.$data.researchField;
        arr.forEach((ele) => {
          ele.ischecked = false;
        });
        const arrq = that.$data.selectedConditionsLIstanbul;
        arrq.forEach((ele, index) => {
          // eslint-disable-next-line eqeqeq
          if (ele.title == " 研究领域 ") {
            arrq.splice(index, 1);
          }
        });
      } else {
        that.$data.researchFieldChecked = 1;
        const arr = that.$data.researchField;
        arr.forEach((ele) => {
          if (ele === item) {
            ele.ischecked = !ele.ischecked;
            if (
              that.$data.researchFieldAlreadyChecked.indexOf(
                ele.INDUSTRY_ID
              ) === -1
            ) {
              that.$data.researchFieldAlreadyChecked.push(ele.INDUSTRY_ID);
            } else {
              const i = that.$data.researchFieldAlreadyChecked.indexOf(
                ele.INDUSTRY_ID
              );
              const arr = that.$data.researchFieldAlreadyChecked;
              arr.splice(i, 1);
            }
          }
        });
        const title =
          event.currentTarget.parentElement.previousElementSibling.innerHTML;
        const conten = item;
        const items = {};
        items.title = title;
        const showchecklist = [];
        arr.forEach((ele) => {
          // eslint-disable-next-line eqeqeq
          if (ele.ischecked == true) {
            showchecklist.push(ele.INDUSTRY_NAME);
          }
        });
        items.showcheck = showchecklist.join(";");
        items.content = conten;
        // 每种分类不能重复添加
        const arr1 = this.$data.selectedConditionsLIstanbul.filter((item2) => {
          return item2.title !== title;
        });
        this.$data.selectedConditionsLIstanbul = arr1;
        if (showchecklist.length > 0) {
          this.$data.selectedConditionsLIstanbul.push(items);
        } else {
          that.$data.researchFieldChecked = -1;
        }
        console.log(this.$data.projectTypesAlreadyChecked);
        this.serarchByUserSelect();
      }

      // const conten = item
      // const items = {}
      // items.title = title
      // items.showcheck = conten.INDUSTRY_NAME
      // items.content = conten
      // const arr = this.$data.selectedConditionsLIstanbul.filter(item2 => {
      //   return item2.title !== title
      // })
      // this.$data.selectedConditionsLIstanbul = arr
      // this.$data.selectedConditionsLIstanbul.push(items)
      // this.$data.researchFieldChecked = item1.INDUSTRY_ID || item1
      // console.log('研究领域选择', this.$data.researchFieldChecked)
      // this.$data.researchFieldAlreadyChecked = item1.INDUSTRY_ID
      // this.serarchByUserSelect()
    },
    // 已选条件点击删除事件
    delSelectedConditions(item) {
      console.log(item);
      console.log(item.title);
      const arr = this.$data.selectedConditionsLIstanbul.filter((item3) => {
        return item3 !== item;
      });
      this.$data.selectedConditionsLIstanbul = arr;
      if (item.title.trim() === "课题类型") {
        this.$data.projectTypesChecked = -1;
        this.$data.projectTypesAlreadyChecked = "";
        this.serarchByUserSelect();
      }
      if (item.title.trim() === "研究领域") {
        this.$data.researchFieldChecked = -1;
        this.$data.researchFieldAlreadyChecked = [];
        const arr = this.$data.researchField;
        arr.forEach((ele) => {
          ele.ischecked = false;
        });
        this.$data.researchField = arr;
        this.serarchByUserSelect();
      }
      if (item.title.trim() === "招标地区") {
        this.$data.supportAreaChecked = -1;
        this.$data.supportAreaAlreadyChecked = "";
        this.serarchByUserSelect();
      }
      if (item.title.trim() === "发布时间") {
        this.$data.releaseTimeChecked = -1;
        this.$data.releaseTimeAlreadyChecked = "";
        this.serarchByUserSelect();
      }
      if (item.title.trim() === "项目状态") {
        this.$data.projectStatusChecked = -1;
        this.$data.projectStatusAlreadyChecked = "";
        this.serarchByUserSelect();
      }
      if (item.title.trim() === "资助金额") {
        this.$data.mingrantsAlready = "";
        this.$data.maxgrantsAlready = "";
        this.$data.grantsChecked = -1;
        this.serarchByUserSelect();
      }
      if (item.title.trim() === "关键词") {
        this.$data.serchkeywords = "";
        this.serarchByUserSelect();
      }
    },
    // 招标地区点击事件
    supportAreaClick(item, event) {
      const item1 = item;
      if (item === -1) {
        item = {
          AREA_NAME: "全国",
          AREA_ID: -1,
        };
      }
      const arr = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];
      if (arr.indexOf(item) !== -1) return;
      const title =
        event.currentTarget.parentElement.previousElementSibling.innerHTML;
      const conten = item;
      const items = {};
      items.title = title;
      items.showcheck = conten.AREA_NAME;
      items.content = conten;
      const arr1 = this.$data.selectedConditionsLIstanbul.filter((item2) => {
        return item2.title !== title;
      });
      this.$data.selectedConditionsLIstanbul = arr1;
      this.$data.selectedConditionsLIstanbul.push(items);
      this.$data.supportAreaChecked = item1.AREA_ID || item1;
      this.$data.supportAreaAlreadyChecked = item1;
      this.serarchByUserSelect();
    },
    // 发布时间点击事件
    releaseTimeClick(item, event) {
      const item1 = item;
      if (item === -1) {
        item = {
          INDUSTRY_NAME: "不限",
        };
      } else if (item === 1) {
        item = {
          INDUSTRY_NAME: "最近7天",
        };
      } else if (item === 2) {
        item = {
          INDUSTRY_NAME: "最近30天",
        };
      }
      const title =
        event.currentTarget.parentElement.previousElementSibling.innerHTML;
      const conten = item;
      const items = {};
      items.title = title;
      items.showcheck = conten.INDUSTRY_NAME;
      items.content = conten;
      const arr = this.$data.selectedConditionsLIstanbul.filter((item2) => {
        return item2.title !== title;
      });
      this.$data.selectedConditionsLIstanbul = arr;
      this.$data.selectedConditionsLIstanbul.push(items);
      this.$data.releaseTimeChecked = item1;
      this.$data.releaseTimeAlreadyChecked = item1;
      this.serarchByUserSelect();
    },
    // 项目状态点击事件
    projectStatusClick(item, event) {
      const item1 = item;
      if (item === -1) {
        item = {
          INDUSTRY_NAME: "不限",
        };
      } else if (item === 1) {
        item = {
          INDUSTRY_NAME: "申报中",
        };
      } else if (item === 2) {
        item = {
          INDUSTRY_NAME: "已结束",
        };
      }
      const title =
        event.currentTarget.parentElement.previousElementSibling.innerHTML;
      const conten = item;
      const items = {};
      items.title = title;
      items.showcheck = conten.INDUSTRY_NAME;
      items.content = conten;
      const arr = this.$data.selectedConditionsLIstanbul.filter((item2) => {
        return item2.title !== title;
      });
      this.$data.selectedConditionsLIstanbul = arr;
      this.$data.selectedConditionsLIstanbul.push(items);
      this.$data.projectStatusChecked = item1;
      this.$data.projectStatusAlreadyChecked = item1;
      this.serarchByUserSelect();
    },
    // 资助金额确定按钮点击事件
    confirmButtonClick(event) {
      this.$data.confirmbuttonIshide = true;
      const title =
        event.currentTarget.parentElement.parentElement.previousElementSibling
          .innerHTML;
      const minprice = this.$data.mingrantsAlready;
      const maxprice = this.$data.maxgrantsAlready;
      if (minprice < 0) {
        this.$message.error("价格不能为负数");
        return;
      } else if (minprice >= maxprice && maxprice !== "") {
        this.$message.error("最大价格必须大于最小价格");
        return;
      }
      const items = {};
      items.title = title;
      items.showcheck = `${minprice}-${maxprice}万元`;
      const arr = this.$data.selectedConditionsLIstanbul.filter((item2) => {
        return item2.title !== title;
      });
      this.$data.grantsChecked = 1;
      this.$data.selectedConditionsLIstanbul = arr;
      this.$data.selectedConditionsLIstanbul.push(items);
      this.serarchByUserSelect();
    },
    // 点击清空所有已选项目
    clearallchecked() {
      this.$data.researchField.forEach((ele) => {
        ele.ischecked = false;
      });
      this.$data.selectedConditionsLIstanbul = [];
      this.$data.researchFieldChecked = -1;
      this.$data.researchFieldAlreadyChecked = [];
      this.$data.supportAreaChecked = -1;
      this.$data.supportAreaAlreadyChecked = "";
      this.$data.releaseTimeChecked = -1;
      this.$data.releaseTimeAlreadyChecked = -1;
      this.$data.projectStatusChecked = -1;
      this.$data.projectStatusAlreadyChecked = "";
      this.$data.mingrantsAlready = "";
      this.$data.maxgrantsAlready = "";
      this.$data.grantsChecked = -1;
      this.$data.serchkeywords = "";
      this.serarchByUserSelect();
    },
    // 搜索框回车触发事件
    search() {
      const items = {};
      items.title = "关键词";
      items.showcheck = this.$data.serchkeywords;
      const arr = this.$data.selectedConditionsLIstanbul.filter((item2) => {
        return item2.title !== "关键词";
      });
      this.$data.selectedConditionsLIstanbul = arr;
      this.$data.selectedConditionsLIstanbul.push(items);
      this.serarchByUserSelect();
    },
    // 搜索图标点击事件
    searchimgclick() {
      const items = {};
      items.title = "关键词";
      items.showcheck = this.$data.serchkeywords;
      const arr = this.$data.selectedConditionsLIstanbul.filter((item2) => {
        return item2.title !== "关键词";
      });
      this.$data.selectedConditionsLIstanbul = arr;
      this.$data.selectedConditionsLIstanbul.push(items);
      this.serarchByUserSelect();
    },
    // 点击标题跳转到详情页
    // 跳转到最新政采招标信息详情页
    toNewTenderDetail(item) {
      localStorage.setItem("navActive", 3);
      this.$store.commit("setnavActive", 3);
      localStorage.setItem("wid", item.TENDER_ID);
      const id = item.TENDER_ID;
      const uid = this.$store.state.userid;
      const params = {
        title_id: id,
        user_id: uid,
        // type: 1
      };
      Addhistoricalrecordzc(params).then(
        (res) => {
          console.log("添加历史记录成功", res);
        },
        (err) => {
          console.log("添加历史记录异常", err);
        }
      );
      let md = item.TENDER_MD5;
      if (md === null) {
        md = "";
      }
      this.$router.push({
        name: "politicsInTheTenderDetails",
        params: {
          id: id,
        },
        query: {
          TENDER_MD5: md,
        },
      });
    },
    // 点击各筛选按钮实时查询数据
    serarchByUserSelect() {
      const that = this;
      const params = {
        // 研究领域
        tender_industry_id: that.$data.researchFieldAlreadyChecked.join(";"),
        // 招标地区
        pro_id:
          that.$data.supportAreaAlreadyChecked === -1
            ? ""
            : that.$data.supportAreaAlreadyChecked.AREA_ID,
        // 项目状态已结束
        tender_status_end:
          that.$data.projectStatusAlreadyChecked === 2
            ? formatDate(Date.parse(new Date()) / 1000)
            : "",
        // 项目状态招标中
        tender_status:
          that.$data.projectStatusAlreadyChecked === 1
            ? formatDate(Date.parse(new Date()) / 1000)
            : "",
        // 发布时间七天内
        release_time_seven:
          that.$data.releaseTimeAlreadyChecked === 1
            ? formatDate(Date.parse(new Date()) / 1000)
            : "",
        // 发布时间三十天内
        release_time_thirty:
          that.$data.releaseTimeAlreadyChecked === 2
            ? formatDate(Date.parse(new Date()) / 1000)
            : "",
        // 项目金额
        help_money_start: that.$data.mingrantsAlready,
        help_money_end: that.$data.maxgrantsAlready,
        limit: that.$data.pageSize,
        page: that.$data.page,
        title: that.$data.serchkeywords,
      };
      // 获取政采招标列表
      getpoliticsInTheTenderList(params).then(
        (res) => {
          console.log(res);
          // 该方法会给res.data中添加dateformate字段用来存储格式化后的创建时间
          that.formateDateList(res.data);

          that.$data.politicsInTheTenderList = res.data;
          that.$data.datatotal = res.count;
          // that.$data.pageTotal = that.$data.ReportingGuidelinesList.length % 12
          that.$data.pageTotal =
            res.count % that.$data.pageSize === 0
              ? Math.floor(res.count / that.$data.pageSize)
              : Math.floor(res.count / that.$data.pageSize) + 1;
        },
        (err) => console.log(err)
      );

      getpoliticsInTheTenderList3(params).then(
        (res) => {
          that.$data.datatotal = res.count;
          that.$data.pageTotal =
            res.count % that.$data.pageSize === 0
              ? Math.floor(res.count / that.$data.pageSize)
              : Math.floor(res.count / that.$data.pageSize) + 1;
          if (that.$data.pageTotal >= 5) {
            that.$data.pageTotal = 5;
          }
        },
        (err) => console.log(err)
      );
    },
    xianzhidjg() {
      let str = this.$data.mingrantsAlready;
      str = str.replace(/[^\d.]/g, ""); // 清除“数字”和“.”以外的字符
      str = str.replace(/\.{2,}/g, "."); // 只保留第一个. 清除多余的
      str = str.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      str = str.replace(/^(-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); // 只能输入两个小数
      if (str.indexOf(".") < 0 && str !== "") {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        str = parseFloat(str);
      }
    },
    // 搜索框失去焦点隐藏下拉
    searchblur() {
      this.$data.isshowsearch = false;
    },
    // 不限价格
    moneynolimit() {
      this.$data.grantsChecked = -1;
      this.$data.mingrantsAlready = "";
      this.$data.maxgrantsAlready = "";
      this.serarchByUserSelect();
    },
    // 根据搜索关键词获取相关领域
    getkeywordsList() {
      const that = this;
      const serchkeywords = this.$data.serchkeywords;
      getKeywords(serchkeywords).then((res) => {
        console.log(res);
        that.$data.keywordsList = res;
      });
    },
    // 关键词选中事件
    keyWordsClick(item) {
      this.$data.serchkeywords = item.KEYWORD_SHOW;
      this.$data.isshowsearch = false;
      this.search();
    },
    // 搜索框输入显示搜索下拉
    searchchange() {
      if (this.$data.serchkeywords === "") {
        this.$data.isshowsearch = false;
        this.serarchByUserSelect();
      } else {
        this.getkeywordsList();
        this.$data.isshowsearch = true;
      }
    },
    // 跳转分享页
    vip() {
      this.$router.push({
        path: "/pc/viproot/vip",
      });
      this.$store.commit("setgundong", true);
    },
    vip1() {
      this.$router.push({
        path: "/pc/viproot/vip",
      });
      this.$store.commit("setgundong", false);
    },
    z_close() {
      this.viptc = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.searcharea {
  width: 300px;
  height: 300px;
  background-color: #fff;
  position: absolute;
  top: 192px;
  right: 1232px;
  padding: 35px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 5px #888888;
  .searcharea-title {
    display: flex;
    font-size: 15px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #999999;
    height: 15px;
    margin-bottom: 18px;
    .title-key {
      display: flex;
      margin-right: 4px;
      .leftborder {
        display: flex;
        flex-direction: column;
        justify-content: end;
        .row {
          height: 2px;
          width: 5px;
          background: #999999;
        }
        .column {
          height: 8px;
          width: 2px;
          background: #999999;
        }
      }
      .rightborder {
        margin-left: 4px;
        height: 15px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        .row {
          height: 2px;
          width: 5px;
          background: #999999;
        }
        .column {
          height: 8px;
          width: 2px;
          background: #999999;
        }
      }
    }
  }
  .searcharea-content {
    font-size: 15px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #323232;
    height: 427px;
    overflow-x: hidden;
    overflow-y: scroll;
    .content-item {
      cursor: pointer;
      box-sizing: border-box;
      // margin-bottom: 18px;
      width: 226px;
      // height: 17px;
      padding: 8px 0px;
      padding-left: 4px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover {
        background-color: #faf7fb;
      }
    }
  }
}
// 版心
.typeArea {
  width: 1200px;
  margin: 0 auto;
}
// 竖线
.vertical-line {
  width: 5px;
  height: 18px;
  background-color: #9702a3;
  margin-right: 4px;
}
// div超出一行显示...
.div-overflow-hidden-1 {
  height: 31px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; //没有效果
}
// 向上小箭头
.shouqiarrow {
  width: 5px;
  height: 5px;
  border-bottom: 3px solid #5e068c;
  border-left: 3px solid #5e068c;
  transform: rotate(135deg) translate(-5px, -7px);
}
// 改变向上箭头方向
.tozhankaiarrow {
  transform: rotate(-45deg) translate(5px, 4px);
}
.background {
  background: #f1f2f6;
  overflow: hidden;
  .title {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
    padding-top: 27px;
    padding-bottom: 26px;
  }
  .screening {
    .search {
      display: flex;
      align-items: center;
      height: 50px;
      background-color: #fff;
      border-bottom: 1px solid #f5f5f5;
      box-sizing: border-box;
      .search-image {
        img {
          height: 16px;
          margin-left: 30px;
          margin-right: 35px;
        }
      }
      .seach-input {
        width: 100%;
        flex: 1;
        input {
          height: 15px;
          width: 100%;
          border: none;
          outline: none;
          font-size: 15px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #999999;
        }
      }
    }
    .classification {
      box-sizing: border-box;
      background-color: #fff;
      padding: 31px 30px;
      display: flex;
      padding-right: 0px;
      padding-bottom: 8px;
      .item {
        display: flex;
        margin-bottom: 27px;
        .item-title {
          height: 28px;
          display: flex;
          align-items: center;
          text-align: center;
          font-size: 15px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #333333;
          opacity: 1;
          margin-right: 20px;
          min-width: 60px;
        }
        .item-content {
          width: 959px;
          display: flex;
          justify-content: left;
          align-items: center;
          flex-wrap: wrap;
          margin-right: -20px;
          span {
            padding: 6px 12px;
            display: flex;
            align-items: center;
            font-size: 15px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #666666;
            opacity: 1;
            margin-right: 8px;
            margin-bottom: 5.5px;
          }
          span.active {
            color: #5e068c;
            background-color: #faf7fb;
            text-align: center;
          }
          .price {
            position: relative;
            display: inline-block;
            display: flex;
            align-items: center;
            margin-right: 0px !important;
            input {
              box-sizing: border-box;
              border: 1px solid #5e068c;
              border-radius: 4px;
              width: 70px;
              height: 28px;
              outline: none;
            }
            .horizontal-line {
              width: 13px;
              height: 2px;
              background-color: #e0e0e0;
              margin: 0 7px;
              padding: 0px !important;
            }
            span {
              font-size: 15px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #999999;
              opacity: 1;
              margin-left: 6px;
            }
            .confirm-button {
              cursor: pointer;
              position: absolute;
              right: -58px;
            }
          }
        }
        // 资助地区特殊样式
        .support-area {
          .active {
            margin-right: 8px;
          }
        }
      }
      .research-field {
        margin-bottom: 17.5px;
      }
      .more-title {
        display: flex;
        align-items: center;
        margin-right: -41px;
        div {
          display: flex;
          margin-right: 53px;
          .item-title {
            margin-right: 20px;
          }
          .item-content {
            margin-right: -20px;
          }
          span {
            padding: 6px 10px !important;
          }
        }
        .project-status {
          div {
            cursor: pointer;
          }
          .item-content {
            width: 205px;
          }
        }
        .time {
          .item-content {
            width: 238px;
          }
        }
        .funding-jine {
          .item-content {
            width: 298px;
          }
        }
      }
      .classification-right {
        .pack-up {
          width: 131px;
          height: 100%;
          font-size: 15px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 50px;
          color: #5e068c;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .selectedConditions {
    margin-top: 30px;
    background-color: #fff;
    padding: 25px 30px;
    display: flex;
    box-sizing: border-box;
    .selectedConditions-title {
      margin-right: 33px;
      font-size: 15px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      opacity: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .content {
      display: flex;
      .item {
        padding: 6px 12px;
        margin-bottom: 5px;
        margin-right: 8px;
        box-sizing: border-box;
        font-size: 15px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #9702a3;
        height: 28px;
        background-color: #fcf7fc;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          margin-right: 5px;
          img {
            width: 15px;
          }
        }
      }
      .clear {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          margin-right: 5px;
          font-size: 15px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #b9b9b9;
          opacity: 1;
          img {
            width: 22px;
          }
        }
      }
    }
  }
  .list {
    margin-top: 29px;
    .total {
      display: flex;
      justify-content: left;
      align-items: center;
      div {
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 20px;
        color: #999999;
        opacity: 1;
        span {
          color: #b55193;
        }
      }
    }
    .list-table {
      margin-top: 11px;
      margin-bottom: 23px;
      table {
        cursor: pointer;
        width: 100%;
        border-spacing: 0px;
        border-collapse: collapse;
        table-layout: fixed;
        thead {
          tr {
            height: 55px;
            border-bottom: 1px solid #f0f0f0;
            td {
              box-sizing: border-box;
              font-size: 15px;
              font-family: PingFang SC;
              font-weight: bold;
              line-height: 21px;
              color: #3b3b3b;
              opacity: 1;
              background-color: #f9f9f9;
            }
            td:nth-of-type(1) {
              text-align: center;
              width: 84px;
            }
            td:nth-of-type(2) {
              width: 411px;
            }
            td:nth-of-type(3) {
              width: 168px;
            }
            td:nth-of-type(4) {
              width: 144px;
            }
            td:nth-of-type(5) {
              text-align: right;
              width: 83px;
            }
            td:nth-of-type(6) {
              text-align: center;
              width: 119px;
            }
            td:nth-of-type(7) {
            }
            td:nth-of-type(8) {
              text-align: right;
              padding-right: 14px;
            }
          }
        }
        tbody {
          background-color: #fff;
          tr {
            border-bottom: 1px solid #f0f0f0;
            height: 60px;
            box-sizing: border-box;
            &:hover {
              background-color: #f9f9f9;
            }
            td {
              box-sizing: border-box;
              font-size: 15px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 18px;
              color: #282828;
              opacity: 1;
            }
            .asfo {
              color: #949494 !important;
            }
            td:nth-of-type(1) {
              text-align: center;
              font-size: 15px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              line-height: 18px;
              color: #990263;
              opacity: 1;
            }
            td:nth-of-type(2) {
              padding-right: 6px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            td:nth-of-type(3) {
              padding-right: 6px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            td:nth-of-type(4) {
              padding-right: 6px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            td:nth-of-type(5) {
              text-align: right;
            }
            td:nth-of-type(6) {
              text-align: center;
              font-size: 15px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              line-height: 18px;
              color: #990263;
              .button-see {
                width: 67px;
                height: 28px;
                border: 1px solid #990263;
                opacity: 1;
                border-radius: 4px;
                text-align: center;
                line-height: 28px;
                color: #990263;
              }
            }
            td:nth-of-type(7) {
            }
            td:nth-of-type(8) {
              text-align: right;
              padding-right: 14px;
            }
            .tdupto {
              font-size: 15px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 18px;
              color: #707070 !important;
            }
          }
        }
      }
    }
  }
  .paging {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 98px;
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px;
    }
    li {
      margin: 0 4px;
      width: 50px;
      height: 50px;
      background-color: #fff;
      // border: 1px solid #b9b9b9;
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        font-size: 15px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #282828;
      }
    }
    .active {
      background: #5e068c;
      border: 1px solid #5e068c;
      opacity: 1;
      a {
        color: #fff;
      }
    }
  }
}
.z_vip {
  background: rgba(4, 4, 4, 0.39);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99;
  left: 0;
  top: 0;
  .dt {
    width: 780px;
    padding-top:55px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.16);
    opacity: 1;
    overflow: hidden;
    border-radius: 20px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 50%;
    margin-top: -245px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    img {
      width: 235px;
    }
    .h1 {
      margin-top: 20px;
      font-size: 26px;
      margin-bottom: 25px;
      font-weight: bold;
      color: #dc7200;
      span{font-size: 40px;color: #990263;}
    }
    .h2 {
      margin-bottom: 33px;
      font-size: 22px;
      font-weight: 400;
      line-height: 34px;
      text-align: center;
      color: #dc7200;
    }
    .anniu {
      width: 100%;
      display: flex;
      a {
        width: 50%;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 25px;
        justify-content: center;
        height: 116px;
        background: rgba(94, 6, 140, 1);
        color: #fff;
        h1{font-size:25px;color:#121212;display: flex;flex-direction: column;line-height:1.5;position: relative;
          span{font-size: 14px;color: #4B4B4B;}
        }
        &:first-child {
          background: linear-gradient(90deg, #ffce86 0%, #ffbc76 100%);
          color: #121212;
        }
        img {
          width: 44px;
          margin-right: 15px;
        }
      }
    }
    .close {
      position: absolute;
      right: 15px;
      top: 15px;
      transform: rotate(45deg);
      color: #b5b5b5;
      font-size: 40px;
      cursor: pointer;
    }
  }
}
</style>
